<template>
  <b-modal
    centered
    id="add-vendor-modal"
    hide-footer
    :title="$t('g.add_vendor')"
    header-class="add-vendor-header"
    size="lg"
  >
    <div class="add-vendor-modal-content">
      <validation-observer ref="addVendor">
        <div
          class="vendor-request-modal-cover position-relative"
          :style="{ backgroundImage: 'url(' + vendoreHeaderImage + ')' }"
        >
          <div class="overlay">
            <label for="header" class="position-absolute p-0 cursor-pointer">
              <img :src="cameraIcon" alt="camera icon" />
            </label>

            <b-form-file
              id="header"
              v-model="header"
              accept="image/*"
              class="d-none"
            />
            <RecommendationUploadImage
              :overlay="true"
              :dimensions="{ width: 1200, height: 750 }"
            />
          </div>
        </div>
        <b-row class="justify-content-center mb-2">
          <validation-provider name="logo" rules="" #default="{ errors }">
            <div style="position: relative">
              <b-avatar
                class="add-vendor-modal-avatar"
                size="8rem"
                :alt="store_name"
                :src="vendorLogo"
                badge-variant="light"
              />
              <b-form-group class="avatar-container" name="name">
                <label for="logo" class="cursor-pointer">
                  <FeatherIcon
                    icon="CameraIcon"
                    class="text-primary"
                    size="17"
                  />
                </label>
                <b-form-file
                  id="logo"
                  name="logo"
                  v-model="logo"
                  accept="image/*"
                  class="d-none"
                />
              </b-form-group>
              <RecommendationUploadImage
                :dimensions="{ width: 110, height: 110 }"
              />
            </div>
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              class="store-owner"
              label-cols="12"
              label-for="store_owner"
              :label="$t('g.vendors/vendorsList/vendors-requests/vendor_owner')"
            >
              <validation-provider
                #default="{ errors }"
                name="store owner"
                rules="required"
              >
                <multiselect
                  label="name"
                  track-by="name"
                  :show-labels="false"
                  :options="users"
                  v-model="store_owner"
                  :placeholder="
                    $t('g.vendors/vendorsList/vendors-requests/vendor_owner')
                  "
                >
                  <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                      <span class="option__title">{{ props.option.name }}</span>
                    </span>
                  </template>
                </multiselect>

                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-cols="12"
              label-for="store_name"
              :label="$t('vendors/vendorsList/vendors-requests/vendor_name')"
            >
              <validation-provider
                #default="{ errors }"
                name="store name"
                rules="required"
              >
                <b-form-input id="store_name" v-model="store_name" />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-cols="12"
              label-for="role"
              :label="$t('g.vendors/vendorsList/vendors-requests/vendor_role')"
            >
              <validation-provider
                #default="{ errors }"
                name="role"
                rules="required"
              >
                <b-form-select id="role" v-model="role_id">
                  <option
                    v-for="{ id, name } in rolesOptions"
                    :key="id"
                    :value="id"
                  >
                    {{ name }}
                  </option>
                </b-form-select>
                <small class="text-danger">
                  {{ errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label-cols="12"
              label-for="priority"
              :label="$t('vendors/vendorsList/vendors-requests/priority')"
            >
              <validation-provider
                #default="{ errors }"
                name="priority"
                rules="required"
              >
                <b-form-select id="priority" v-model="priority">
                  <option :value="5">
                    {{ $t("vendors/vendorsList/accepted-vendors/very_high") }}
                  </option>
                  <option :value="4">
                    {{ $t("vendors/vendorsList/accepted-vendors/high") }}
                  </option>
                  <option :value="3">
                    {{ $t("vendors/vendorsList/accepted-vendors/medium") }}
                  </option>
                  <option :value="2">
                    {{ $t("vendors/vendorsList/accepted-vendors/low") }}
                  </option>
                  <option :value="1">
                    {{ $t("vendors/vendorsList/accepted-vendors/very_low") }}
                  </option>
                </b-form-select>
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
          label-cols="12"
          label-for="about"
          :label="$t('vendors/vendorsList/vendors-requests/description')"
        >
          <validation-provider #default="{ errors }" name="about">
            <b-form-textarea id="about" v-model="about" rows="5" no-resize />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>

        <b-row>
          <b-col lg="4">
            <b-form-group
              :label="$t('g.commercial_number')"
              label-for="commercial_number"
            >
              <validation-provider
                #default="{ errors }"
                name="Commercial number"
                vid="commercial_number"
                rules="required"
              >
                <b-form-input
                  type="number"
                  id="commercial_number"
                  v-model="commercial_number"
                  :state="errors.length > 0 ? false : null"
                  name="commercial_number"
                  :placeholder="$t('g.commercial_number')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="4" class="px-lg-0">
            <b-form-group
              :label="$t('g.commercial_expiry_date')"
              label-for="id_expiry_date"
              class="custom-date"
            >
              <validation-provider
                #default="{ errors }"
                name="commercial expiry date"
                vid="commercial_expiry_date"
                rules="required"
              >
                <b-form-datepicker
                  v-model="id_expiry_date"
                  :placeholder="$t('g.commercial_expiry_date')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="4">
            <b-form-group :label="$t('g.id_number')" label-for="id_number">
              <validation-provider
                #default="{ errors }"
                name="ID number"
                vid="id_number"
                rules="required"
              >
                <b-form-input
                  type="number"
                  id="id_number"
                  v-model="id_number"
                  :state="errors.length > 0 ? false : null"
                  name="id_number"
                  :placeholder="$t('g.id_number')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="doc_image">
          <b-col>
            <b-form-group :label="$t('g.commercial_number_image')" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="Commercial number image"
                rules="required"
              >
                <div class="image-input-area">
                  <b-form-file
                    class="image-input"
                    ref="commercial_number_image"
                    v-model="commercial_number_image"
                    accept="image/*"
                  />
                  <div class="image-icon">
                    <img
                      v-if="
                        !commercial_number_image ||
                        commercial_number_image.length == 0
                      "
                      :src="idCardIcon"
                      alt="Cover"
                    />

                    <img
                      v-else
                      :src="vendorCommercial"
                      alt="Commercial number image"
                    />
                  </div>
                  <p
                    v-if="
                      !commercial_number_image ||
                      commercial_number_image.length == 0
                    "
                    class="image-text mb-0"
                  >
                    {{ $t("g.upload_photo") }}
                  </p>
                </div>
                <RecommendationUploadImage
                  v-if="
                    !commercial_number_image ||
                    commercial_number_image.length == 0
                  "
                  :dimensions="{ width: 1200, height: 750 }"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('g.id_number_image')" class="mb-2">
              <validation-provider
                #default="{ errors }"
                name="id number image"
                rules=""
              >
                <div class="image-input-area">
                  <b-form-file
                    class="image-input"
                    ref="header"
                    v-model="id_number_image"
                    accept="image/*"
                  />
                  <div class="image-icon">
                    <img
                      v-if="!id_number_image || id_number_image.length == 0"
                      :src="idCardIcon"
                      alt="Cover"
                    />
                    <img v-else :src="vendorID" alt="Cover" />
                  </div>
                  <p
                    v-if="!id_number_image || id_number_image.length == 0"
                    class="image-text mb-0"
                  >
                    {{ $t("g.upload_photo") }}
                  </p>
                </div>
                <RecommendationUploadImage
                  v-if="!id_number_image || id_number_image.length == 0"
                  :dimensions="{ width: 1200, height: 750 }"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="actions">
          <b-button variant="primary" @click="submit"
            >{{ $t("g.add_vendor") }}
          </b-button>
        </b-row>
      </validation-observer>
      <overlay-component :isLoading="isLoading" />
    </div>
  </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import OverlayComponent from "@/components/shared/OverlayComponent";
import RecommendationUploadImage from "@/components/shared/RecommendationUploadImage/index";

import {
  BModal,
  BFormSelect,
  BAvatar,
  BFormInput,
  BCol,
  BFormGroup,
  BFormTextarea,
  BImg,
  BRow,
  BFormFile,
  BButton,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  name: "addVendorModal",
  components: {
    BModal,
    BFormSelect,
    BAvatar,
    BFormInput,
    BCol,
    BFormGroup,
    BFormTextarea,
    BImg,
    BRow,
    BFormFile,
    BButton,
    ValidationProvider,
    ValidationObserver,
    OverlayComponent,
    BFormDatepicker,
    Multiselect,
    RecommendationUploadImage,
  },
  data() {
    return {
      logo: null,
      store_name: null,
      about: null,
      priority: 5,
      header: null,
      commercial_number: null,
      commercial_number_image: null,
      id_number_image: null,
      okStatus: false,
      store_owner: null,
      cameraIcon: require("@/assets/images/icons/camera.svg"),
      vendorLogo: null,
      user_id: null,
      vendoreHeaderImage: require("@/assets/images/pages/vendor-cover.jpeg"),
      idCardIcon: require("@/assets/images/pages/idCard.png"),
      editIcon: require("@/assets/images/icons/pencil.svg"),
      imageIcon: require("@/assets/images/icons/photo.png"),
      users: [],
      role_id: null,
      rolesOptions: null,
      isLoading: false,
      id_number: null,
      id_expiry_date: null,
      vendorCommercial: null,
      vendorID: null,
      isHeader: false,
      isLogo: false,
    };
  },
  beforeMount() {
    this.getUsers();
    this.getVendorRoles();
  },
  methods: {
    async submit() {
      this.isLoading = true;
      try {
        const validate = await this.$refs.addVendor.validate();
        if (validate) {
          const formData = new FormData();
          const bEObjectItems = [
            "priority",
            "header",
            "about",
            "store_name",
            "logo",
            "user_id",
            "role_id",
            "commercial_number",
            "commercial_number_image",
            "id_number",
            "id_expiry_date",
            "id_number_image",
          ];
          for (let item of bEObjectItems) {
            if (this[item] != null) {
              formData.append(item, this[item]);
            }
          }
          formData.append("is_approved", 1);
          const request = await this.$http.post("admin/vendors", formData);
          if (request.status === 201 || request.status === 200) {
            this.$emit("update-table", true);
            this.$helpers.makeToast(
              "success",
              request.data.message,
              request.data.message
            );
            this.$bvModal.hide("add-vendor-modal");
          }
        }
      } catch (errors) {
        this.isLoading = false;
        this.$helpers.handleError(errors);
      } finally {
        this.isLoading = false;
      }
    },
    async getUsers() {
      this.isLoading = true;
      try {
        const response = await this.$http.get("admin/users");
        if (response.status == 200) {
          this.users = this.handelUsersList(response);
        }
      } catch (err) {
        this.isLoading = false;

        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    async getVendorRoles() {
      this.isLoading = true;

      try {
        const response = await this.$http.get("admin/roles?guard=vendor");
        if (response.status === 200) {
          this.rolesOptions = this.handelRolesOptions(response);
        }
      } catch (err) {
        this.isLoading = false;

        this.$helpers.handleError(err);
      } finally {
        this.isLoading = false;
      }
    },
    handelUsersList(response) {
      let list = [];
      response.data?.data.map(
        ({ id, first_name, last_name, middle_name, user_name }) => {
          list.push({
            id,
            name: `${first_name ? first_name : " "} ${
              middle_name ? middle_name : ""
            } ${last_name ? last_name : " "}`,
            user_name,
          });
        }
      );
      return list;
    },
    handelRolesOptions(response) {
      let options = [];
      response.data?.data.map(({ id, name }) => {
        options.push({
          id,
          name,
        });
      });
      return options;
    },
  },
  watch: {
    header: function (val) {
      val ? (this.isHeader = true) : null;
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.vendoreHeaderImage = URL.createObjectURL(val);
      }
    },
    logo: function (val) {
      val ? (this.isLogo = true) : null;
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.vendorLogo = URL.createObjectURL(val);
      }
    },
    id_number_image: function (val) {
      val ? (this.isIdImage = true) : null;
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.vendorID = URL.createObjectURL(val);
      }
    },
    commercial_number_image: function (val) {
      val ? (this.isCommercialImage = true) : null;
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.vendorCommercial = URL.createObjectURL(val);
      }
    },
    store_owner(newValue) {
      if (this.users.length > 0) {
        this.user_id = newValue.id;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./index";
</style>
