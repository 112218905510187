<template>
  <div class="accepted-vendors">
    <b-card
      :class="{
        'accepted-vendors-card--on-loading':
          $helpers.isNull(rows) || ($helpers.isset(rows) && !rows.length),
      }"
    >
      <b-row class="w-100 d-flex justify-content-end my-1">
        <b-button
          variant="primary"
          v-if="globalPermission.vendors && globalPermission.vendors.store"
          @click="$bvModal.show('add-vendor-modal')"
        >
          {{ $t("g.add_vendor") }}
        </b-button>
      </b-row>
      <b-row class="mb-2">
        <b-col
          cols="12"
          class="d-flex justify-content-between align-items-center"
        >
          <h3 class="d-inline-block">
            {{ $t("vendors/vendorsList/accepted-vendors") }}
          </h3>
          <b-input-group size="sm" class="w-25">
            <b-form-input
              v-model="filter"
              type="search"
              :placeholder="$t('g.type_to_search')"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <Loading v-if="$helpers.isNull(rows)" />
      <NoDataFound v-else-if="$helpers.isset(rows) && !rows.length" />
      <div v-else class="accepted-vendors-table">
        <b-table
          id="accepted-vendors-table"
          hover
          borderless
          responsive
          table-class="table-accepted-vendors"
          :fields="cols"
          :items="searchResult"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(#)="row">
            {{ row.item.index }}
          </template>
          <template #cell(logo)="row">
            <b-avatar
              variant="light"
              :src="
                $helpers.filterImages(
                  'path',
                  row.item.logo,
                  row.item.store_name
                )
              "
              :alt="row.item.store_name"
            />
          </template>

          <template #cell(priority)="row">
            <b-badge variant="light-success" v-if="row.item.priority === 5">
              {{ $t("vendors/vendorsList/accepted-vendors/very_high") }}
            </b-badge>
            <b-badge
              variant="light-primary"
              v-else-if="row.item.priority === 4"
            >
              {{ $t("vendors/vendorsList/accepted-vendors/high") }}
            </b-badge>
            <b-badge
              variant="light-secondary"
              v-else-if="row.item.priority === 3"
            >
              {{ $t("vendors/vendorsList/accepted-vendors/medium") }}
            </b-badge>
            <b-badge
              variant="light-warning"
              v-else-if="row.item.priority === 2"
            >
              {{ $t("vendors/vendorsList/accepted-vendors/low") }}
            </b-badge>
            <b-badge variant="light-danger" v-else>
              {{ $t("vendors/vendorsList/accepted-vendors/very_low") }}
            </b-badge>
          </template>

          <template #cell(details)="row" v-if="globalPermission.vendors.show">
            <span
              style="color: #2b79ad"
              role="button"
              @click="openVendorDetails(row.item)"
            >
              {{ $t("g.view") }}
            </span>
          </template>

          <template #cell(actions)="row" v-if="globalPermission.vendors.update">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <FeatherIcon icon="MoreVerticalIcon" class="cursor-pointer" />
              </template>
              <b-dropdown-item @click="openEditModel(row.item)">
                <FeatherIcon icon="Edit2Icon" class="mr-50" />
                {{ $t("g.edit") }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows.length"
          :per-page="perPage"
          aria-controls="accepted-vendors-table"
          align="right"
        />
      </div>
    </b-card>
    <add-vendor
      v-if="
        globalPermission &&
        globalPermission.vendors &&
        globalPermission.vendors.store
      "
      @update-table="updateData"
    />
    <EditVendorDataModal
      :page_data="page_data"
      :header_path="header"
      :logo_path="logo"
      :about="about"
      :store_name="store_name"
      :priority="priority"
      @resetPopup="resetData"
    />
    <ShowVendorDetails :data_item="data_item" />
  </div>
</template>

<script>
import AddVendor from "@/components/vendors/AddVendor";
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BInputGroup,
  BFormInput,
  BAvatar,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBadge,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import VendorRequestModal from "@/components/vendors/VendorRequestModal/index.vue";
import EditVendorModal from "@/components/vendors/EditVendorModal/index.vue";
import EditVendorDataModal from "@/components/vendors/EditVendorModal/EditVendorModal.vue";
import NoDataFound from "@/components/shared/NoDataFound/index.vue";
import ShowVendorDetails from "../showVendorDetails/ShowVendorDetails.vue";

export default {
  name: "AcceptedVendors",
  components: {
    NoDataFound,
    EditVendorModal,
    VendorRequestModal,
    BLink,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BButton,
    BAvatar,
    BInputGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    Loading,
    EditVendorDataModal,
    AddVendor,
    ShowVendorDetails,
  },
  data() {
    return {
      cols: [
        {
          key: "#",
          label: "#",
          sortable: false,
        },
        {
          key: "logo",
          label: this.$t("vendors/vendorsList/accepted-vendors/logo"),
          sortable: false,
        },
        {
          key: "store_name",
          label: this.$t("vendors/vendorsList/accepted-vendors/name"),
          sortable: true,
        },
        {
          key: "priority",
          label: this.$t("vendors/vendorsList/accepted-vendors/priority"),
          sortable: true,
        },
        {
          key: "details",
          label: this.$t("vendors/vendorsList/accepted-vendors/details"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("vendors/vendorsList/accepted-vendors/actions"),
          sortable: false,
        },
      ],
      rows: null,
      perPage: 10,
      currentPage: 1,
      filter: null,
      page_data: null,
      header: null,
      about: null,
      logo: null,
      store_name: null,
      priority: null,
      show_modal: false,
      data_item: null,
    };
  },
  created() {
    this.getAcceptedVendors();
  },
  computed: {
    searchResult() {
      if (this.filter) {
        return this.rows.filter((item) => {
          return item.store_name
            .toLowerCase()
            .includes(this.filter.toLowerCase());
        });
      } else {
        return this.rows;
      }
    },
  },
  methods: {
    openVendorDetails(item) {
      this.data_item = item;
      this.$bvModal.show("vendor-details");
    },
    getAcceptedVendors() {
      this.$http
        .get("admin/vendors?approved=true")
        .then((response) => {
          this.rows = response.data.data;
          this.rows.map((item, index) => {
            item.index = index + 1;
          });
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    async openEditModel(data) {
      this.store_name = await data.store_name;
      this.$bvModal.show(`edit-modal-vendor`);
      this.show_modal = true;
      this.page_data = await data;
      this.header = await data.header?.path;
      this.about = await data.about;
      this.logo = await data.logo?.path;
      this.priority = await data.priority;
    },
    resetData() {
      this.page_data = null;
      this.show_modal = false;
    },
    updateData() {
      this.getAcceptedVendors();
    },
  },
  watch: {
    "$store.state.vendor.isApproved"(val) {
      if (val) {
        this.getAcceptedVendors();
        this.$store.dispatch("vendor/setIsApproved", false);
      }
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss" />
