<template>
  <b-modal
    id="vendor-details"
    :title="$t('g.show_vendor')"
    hide-footer
    centered
    modal-class="vendor-details"
  >
    <div v-if="data_item" class="vendor-request-modal-content">
      <b-img
        :src="vendoreHeaderImage"
        :alt="data_item.store_name"
        class="vendor-request-modal-cover"
        fluid-grow
      />
      <b-avatar
        class="vendor-request-modal-avatar"
        size="8rem"
        :alt="data_item.store_name"
        :src="
          $helpers.filterImages('path', data_item.logo, data_item.store_name)
        "
      />
      <b-row class="vendor-request-modal-details">
        <b-col cols="12">
          <b-form-group
            label-cols="12"
            label-for="store_name"
            :label="$t('vendors/vendorsList/vendors-requests/vendor_name')"
          >
            <b-form-input
              id="store_name"
              :value="data_item.store_name"
              readonly
            />
          </b-form-group>
          <b-form-group
            label-cols="12"
            label-for="description"
            :label="$t('vendors/vendorsList/vendors-requests/description')"
          >
            <b-form-textarea
              id="description"
              :value="data_item.about"
              rows="5"
              no-resize
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <ul class="vendor-info">
        <li>
          <span>{{ $t("g.id_number") }}:</span>
          <strong>{{ data_item.id_number }}</strong>
        </li>

        <li>
          <span>{{ $t("g.id_number_image") }}:</span>

          <strong>
            <a v-if="id_number_image" :href="id_number_image" target="_blank">{{
              $t("g.view_file")
            }}</a>
            <span v-else>{{ $t("g.id_image_not_found") }}</span>
          </strong>
        </li>
        <li>
          <span>{{ $t("g.commercial_number") }}:</span>
          <strong>{{
            data_item.commercial_number
              ? data_item.commercial_number
              : $t("g.noData")
          }}</strong>
        </li>
        <li>
          <span>{{ $t("g.commercial_expiry_date") }}:</span>
          <strong>{{
            data_item.id_expiry_date ? data_item.id_expiry_date : $t("g.noData")
          }}</strong>
        </li>
        <li>
          <span>{{ $t("g.commercial_number_image") }}:</span>
          <strong>
            <a
              v-if="commercial_number_image"
              :href="commercial_number_image"
              target="_blank"
              >{{ $t("g.view_file") }}</a
            >
            <span v-else>{{ $t("g.commercial_image_not_found") }}</span>
          </strong>
        </li>
      </ul>
    </div>
  </b-modal>
</template>

<script>
import {
  BImg,
  BCol,
  BRow,
  BAvatar,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BForm,
} from "bootstrap-vue";
export default {
  name: "ShowVendorDetails",
  components: {
    BImg,
    BCol,
    BRow,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    BForm,
  },
  props: ["data_item"],
  data() {
    return {
      vendoreHeaderImage: null,
      commercial_number_image: null,
      id_number_image: null,
    };
  },
  watch: {
    "data_item.slug": function (val) {
      (this.vendoreHeaderImage = this.$helpers.isset(this.data_item.header)
        ? this.$helpers.filterImages(
            "path",
            this.data_item.header,
            this.data_item.store_name
          )
        : require("@/assets/images/pages/vendor-cover.jpeg")),
        (this.commercial_number_image =
          this.data_item?.commercial_number_image?.path);
      this.id_number_image = this.data_item?.id_number_image?.path;
    },
  },
};
</script>

<style lang="scss">
@import "./ShowVendorDetails";
</style>
