<template>
  <EditModal
    type="vendor"
    :id="row.item.slug"
    :title_name="store_name"
    :ok-status="okStatus"
    @submit="editVendor"
    @hidden="resetData"
    @show="resetData"
  >
    <validation-observer ref="editVendor">
      <div class="vendor-request-modal-content">
        <div
          class="vendor-request-modal-cover position-relative"
          :style="{ backgroundImage: 'url(' + vendoreHeaderImage + ')' }"
        >
          <label
            for="header_image"
            class="position-absolute p-0 cursor-pointer"
          >
            <img :src="cameraIcon" alt="camera icon" />
          </label>
          <b-form-file
            id="header_image"
            v-model="header_image"
            accept="image/*"
            class="d-none"
          />
        </div>
        <b-avatar
          class="vendor-request-modal-avatar"
          size="8rem"
          :alt="store_name"
          :src="vendorLogo"
          badge
          badge-variant="light"
        >
          <template #badge>
            <label for="logo" class="cursor-pointer">
              <FeatherIcon icon="CameraIcon" class="text-primary" size="17" />
            </label>
            <b-form-file
              id="logo"
              v-model="logo"
              accept="image/*"
              class="d-none"
            />
          </template>
        </b-avatar>
        <b-form-group
          label-cols="12"
          label-for="store_name"
          :label="$t('vendors/vendorsList/vendors-requests/vendor_name')"
        >
          <validation-provider
            #default="{ errors }"
            name="store owner"
            rules="required"
          >
            <b-form-input id="store_name" v-model="store_name" />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-for="about"
          :label="$t('vendors/vendorsList/vendors-requests/description')"
        >
          <validation-provider #default="{ errors }" name="about">
            <b-form-textarea id="about" v-model="about" rows="5" no-resize />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <!-- priority -->
        <b-form-group
          label-cols="12"
          label-for="priority"
          :label="$t('vendors/vendorsList/vendors-requests/priority')"
        >
          <b-form-select id="priority" v-model="priority">
            <option :value="5">
              {{ $t("vendors/vendorsList/accepted-vendors/very_high") }}
            </option>
            <option :value="4">
              {{ $t("vendors/vendorsList/accepted-vendors/high") }}
            </option>
            <option :value="3">
              {{ $t("vendors/vendorsList/accepted-vendors/medium") }}
            </option>
            <option :value="2">
              {{ $t("vendors/vendorsList/accepted-vendors/low") }}
            </option>
            <option :value="1">
              {{ $t("vendors/vendorsList/accepted-vendors/very_low") }}
            </option>
          </b-form-select>
        </b-form-group>
      </div>
    </validation-observer>
  </EditModal>
</template>

<script>
import EditModal from "@/components/shared/EditModal/index.vue";
import {
  BFormSelect,
  BAvatar,
  BFormInput,
  BCol,
  BFormGroup,
  BFormTextarea,
  BImg,
  BRow,
  BFormFile,
} from "bootstrap-vue";

export default {
  name: "EditVendorModal",
  components: {
    BFormSelect,
    BAvatar,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    EditModal,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      logo: null,
      store_name: null,
      about: null,
      header_image: null,
      priority: null,
      okStatus: false,
      cameraIcon: require("@/assets/images/icons/camera.svg"),
      vendorLogo: null,
      vendoreHeaderImage: null,
    };
  },
  created() {
    this.store_name = this.row.item.store_name;
    this.about = this.row.item.about;
    this.priority = this.row.item.priority;
    this.vendorLogo = this.$helpers.filterImages(
      "path",
      this.row.item.logo,
      this.row.item.store_name
    );
    this.vendoreHeaderImage = this.$helpers.filterImages(
      "path",
      this.row.item.header,
      this.row.item.store_name,
      require("@/assets/images/pages/vendor-cover.jpeg")
    );
  },
  methods: {
    resetData() {
      this.okStatus = false;
      this.logo = null;
      this.header_image = null;
      this.store_name = this.row.item.store_name;
      this.about = this.row.item.about;
      this.priority = this.row.item.priority;
      this.vendorLogo = this.$helpers.filterImages(
        "path",
        this.row.item.logo,
        this.row.item.store_name
      );
      this.vendoreHeaderImage = this.$helpers.filterImages(
        "path",
        this.row.item.header,
        this.row.item.store_name,
        require("@/assets/images/pages/vendor-cover.jpeg")
      );
    },
    async editVendor() {
    },
  },
  watch: {
    header_image: function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.vendoreHeaderImage = URL.createObjectURL(val);
      }
    },
    logo: function (val) {
      if (this.$helpers.isset(val) && this.$helpers.isObject(val)) {
        this.vendorLogo = URL.createObjectURL(val);
      }
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
