var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","id":"add-vendor-modal","hide-footer":"","title":_vm.$t('g.add_vendor'),"header-class":"add-vendor-header","size":"lg"}},[_c('div',{staticClass:"add-vendor-modal-content"},[_c('validation-observer',{ref:"addVendor"},[_c('div',{staticClass:"vendor-request-modal-cover position-relative",style:({ backgroundImage: 'url(' + _vm.vendoreHeaderImage + ')' })},[_c('div',{staticClass:"overlay"},[_c('label',{staticClass:"position-absolute p-0 cursor-pointer",attrs:{"for":"header"}},[_c('img',{attrs:{"src":_vm.cameraIcon,"alt":"camera icon"}})]),_c('b-form-file',{staticClass:"d-none",attrs:{"id":"header","accept":"image/*"},model:{value:(_vm.header),callback:function ($$v) {_vm.header=$$v},expression:"header"}}),_c('RecommendationUploadImage',{attrs:{"overlay":true,"dimensions":{ width: 1200, height: 750 }}})],1)]),_c('b-row',{staticClass:"justify-content-center mb-2"},[_c('validation-provider',{attrs:{"name":"logo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticStyle:{"position":"relative"}},[_c('b-avatar',{staticClass:"add-vendor-modal-avatar",attrs:{"size":"8rem","alt":_vm.store_name,"src":_vm.vendorLogo,"badge-variant":"light"}}),_c('b-form-group',{staticClass:"avatar-container",attrs:{"name":"name"}},[_c('label',{staticClass:"cursor-pointer",attrs:{"for":"logo"}},[_c('FeatherIcon',{staticClass:"text-primary",attrs:{"icon":"CameraIcon","size":"17"}})],1),_c('b-form-file',{staticClass:"d-none",attrs:{"id":"logo","name":"logo","accept":"image/*"},model:{value:(_vm.logo),callback:function ($$v) {_vm.logo=$$v},expression:"logo"}})],1),_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 110, height: 110 }}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"store-owner",attrs:{"label-cols":"12","label-for":"store_owner","label":_vm.$t('g.vendors/vendorsList/vendors-requests/vendor_owner')}},[_c('validation-provider',{attrs:{"name":"store owner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('multiselect',{attrs:{"label":"name","track-by":"name","show-labels":false,"options":_vm.users,"placeholder":_vm.$t('g.vendors/vendorsList/vendors-requests/vendor_owner')},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name))])])]}}],null,true),model:{value:(_vm.store_owner),callback:function ($$v) {_vm.store_owner=$$v},expression:"store_owner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-for":"store_name","label":_vm.$t('vendors/vendorsList/vendors-requests/vendor_name')}},[_c('validation-provider',{attrs:{"name":"store name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"store_name"},model:{value:(_vm.store_name),callback:function ($$v) {_vm.store_name=$$v},expression:"store_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-for":"role","label":_vm.$t('g.vendors/vendorsList/vendors-requests/vendor_role')}},[_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"role"},model:{value:(_vm.role_id),callback:function ($$v) {_vm.role_id=$$v},expression:"role_id"}},_vm._l((_vm.rolesOptions),function(ref){
var id = ref.id;
var name = ref.name;
return _c('option',{key:id,domProps:{"value":id}},[_vm._v(" "+_vm._s(name)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0])+" ")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","label-for":"priority","label":_vm.$t('vendors/vendorsList/vendors-requests/priority')}},[_c('validation-provider',{attrs:{"name":"priority","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"priority"},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}},[_c('option',{domProps:{"value":5}},[_vm._v(" "+_vm._s(_vm.$t("vendors/vendorsList/accepted-vendors/very_high"))+" ")]),_c('option',{domProps:{"value":4}},[_vm._v(" "+_vm._s(_vm.$t("vendors/vendorsList/accepted-vendors/high"))+" ")]),_c('option',{domProps:{"value":3}},[_vm._v(" "+_vm._s(_vm.$t("vendors/vendorsList/accepted-vendors/medium"))+" ")]),_c('option',{domProps:{"value":2}},[_vm._v(" "+_vm._s(_vm.$t("vendors/vendorsList/accepted-vendors/low"))+" ")]),_c('option',{domProps:{"value":1}},[_vm._v(" "+_vm._s(_vm.$t("vendors/vendorsList/accepted-vendors/very_low"))+" ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label-cols":"12","label-for":"about","label":_vm.$t('vendors/vendorsList/vendors-requests/description')}},[_c('validation-provider',{attrs:{"name":"about"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"about","rows":"5","no-resize":""},model:{value:(_vm.about),callback:function ($$v) {_vm.about=$$v},expression:"about"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.commercial_number'),"label-for":"commercial_number"}},[_c('validation-provider',{attrs:{"name":"Commercial number","vid":"commercial_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"commercial_number","state":errors.length > 0 ? false : null,"name":"commercial_number","placeholder":_vm.$t('g.commercial_number')},model:{value:(_vm.commercial_number),callback:function ($$v) {_vm.commercial_number=$$v},expression:"commercial_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-lg-0",attrs:{"lg":"4"}},[_c('b-form-group',{staticClass:"custom-date",attrs:{"label":_vm.$t('g.commercial_expiry_date'),"label-for":"id_expiry_date"}},[_c('validation-provider',{attrs:{"name":"commercial expiry date","vid":"commercial_expiry_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"placeholder":_vm.$t('g.commercial_expiry_date')},model:{value:(_vm.id_expiry_date),callback:function ($$v) {_vm.id_expiry_date=$$v},expression:"id_expiry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.id_number'),"label-for":"id_number"}},[_c('validation-provider',{attrs:{"name":"ID number","vid":"id_number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"id_number","state":errors.length > 0 ? false : null,"name":"id_number","placeholder":_vm.$t('g.id_number')},model:{value:(_vm.id_number),callback:function ($$v) {_vm.id_number=$$v},expression:"id_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"doc_image"},[_c('b-col',[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.commercial_number_image')}},[_c('validation-provider',{attrs:{"name":"Commercial number image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"commercial_number_image",staticClass:"image-input",attrs:{"accept":"image/*"},model:{value:(_vm.commercial_number_image),callback:function ($$v) {_vm.commercial_number_image=$$v},expression:"commercial_number_image"}}),_c('div',{staticClass:"image-icon"},[(
                      !_vm.commercial_number_image ||
                      _vm.commercial_number_image.length == 0
                    )?_c('img',{attrs:{"src":_vm.idCardIcon,"alt":"Cover"}}):_c('img',{attrs:{"src":_vm.vendorCommercial,"alt":"Commercial number image"}})]),(
                    !_vm.commercial_number_image ||
                    _vm.commercial_number_image.length == 0
                  )?_c('p',{staticClass:"image-text mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),(
                  !_vm.commercial_number_image ||
                  _vm.commercial_number_image.length == 0
                )?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 750 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('g.id_number_image')}},[_c('validation-provider',{attrs:{"name":"id number image","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('div',{staticClass:"image-input-area"},[_c('b-form-file',{ref:"header",staticClass:"image-input",attrs:{"accept":"image/*"},model:{value:(_vm.id_number_image),callback:function ($$v) {_vm.id_number_image=$$v},expression:"id_number_image"}}),_c('div',{staticClass:"image-icon"},[(!_vm.id_number_image || _vm.id_number_image.length == 0)?_c('img',{attrs:{"src":_vm.idCardIcon,"alt":"Cover"}}):_c('img',{attrs:{"src":_vm.vendorID,"alt":"Cover"}})]),(!_vm.id_number_image || _vm.id_number_image.length == 0)?_c('p',{staticClass:"image-text mb-0"},[_vm._v(" "+_vm._s(_vm.$t("g.upload_photo"))+" ")]):_vm._e()],1),(!_vm.id_number_image || _vm.id_number_image.length == 0)?_c('RecommendationUploadImage',{attrs:{"dimensions":{ width: 1200, height: 750 }}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"actions"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("g.add_vendor"))+" ")])],1)],1),_c('overlay-component',{attrs:{"isLoading":_vm.isLoading}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }