<template>
  <div class="vendors-list">
    <b-form-group v-slot="{ ariaDescribedby }" class="choose-option">
      <b-form-radio
        v-model="is_request"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        :value="true"
        >{{ $t("g.vendors") }}</b-form-radio
      >
      <b-form-radio
        class="second-radio"
        v-model="is_request"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        :value="false"
        >{{ $t("vendors/vendorsList/vendors-requests") }}</b-form-radio
      >
      <b-badge v-if="newRequest" variant="danger">{{ newRequest }}</b-badge>
    </b-form-group>
    <VendorsRequests @requestNumber="getNumber" v-if="!is_request" />
    <AcceptedVendors v-if="is_request" />
  </div>
</template>

<script>
import { BFormGroup, BFormRadio, BBadge } from "bootstrap-vue";
import AcceptedVendors from "@/components/vendors/AcceptedVendors/index.vue";
import VendorsRequests from "@/components/vendors/VendorsRequests/index.vue";

export default {
  name: "VendorsList",
  components: {
    VendorsRequests,
    AcceptedVendors,
    BFormGroup,
    BFormRadio,
    BBadge
  },
  data() {
    return {
      is_request: true,
      newRequest:0
    };
  },
  methods:{
    getNumber(Number){
      this.newRequest = Number
    }
  }
};
</script>

<style src="./_index.scss" lang="scss" scoped />
