<template>
  <div class="vendors-requests">
    <b-card
      :class="{
        'vendors-requests-card--on-loading':
          $helpers.isNull(rows) || ($helpers.isset(rows) && !rows.length),
      }"
    >
      <b-row class="mb-2">
        <b-col
          cols="12"
          class="d-flex justify-content-between align-items-center"
        >
          <h3 class="d-inline-block">
            {{ $t("vendors/vendorsList/vendors-requests") }}
          </h3>
          <b-input-group size="sm" class="w-25">
            <b-form-input
              v-model="filter"
              type="search"
              :placeholder="$t('g.type_to_search')"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <Loading v-if="$helpers.isNull(rows)" />
      <NoDataFound v-else-if="$helpers.isset(rows) && !rows.length" />
      <div v-else class="vendors-requests-table">
        <b-table
          id="vendors-requests-table"
          hover
          borderless
          responsive
          table-class="table-vendors-requests"
          :fields="cols"
          :items="searchResult"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #cell(#)="row">
            {{ row.item.index }}
          </template>
          <template #cell(logo)="row">
            <b-avatar
              variant="light"
              :src="
                $helpers.filterImages(
                  'path',
                  row.item.logo,
                  row.item.store_name
                )
              "
              :alt="row.item.store_name"
            />
          </template>

          <template #cell(status)="row">
            <b-badge variant="light-primary" v-if="!row.item.is_approved">
              {{ $t("vendors/vendorsList/vendors-requests/pending") }}
            </b-badge>
            <b-badge variant="light-danger" v-else>
              {{ $t("vendors/vendorsList/vendors-requests/rejected") }}
            </b-badge>
          </template>

          <template #cell(details)="row" v-if="globalPermission.vendors.show">
            <VendorRequestModal
              v-if="
                globalPermission.vendors.show && globalPermission.vendors.update
              "
              @update-table="update"
              :row="row"
              @vendorApproved="filterVendor"
            />
          </template>

          <template #cell(actions)="row" v-if="globalPermission.vendors.update">
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <FeatherIcon icon="MoreVerticalIcon" class="cursor-pointer" />
              </template>
              <b-dropdown-item @click="$bvModal.show('edit-modal-vendor')">
                {{ $t("g.edit") }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows.length"
          :per-page="perPage"
          aria-controls="vendors-requests-table"
          align="right"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  BDropdown,
  BFormInput,
  BInputGroup,
  BPagination,
  BDropdownItem,
} from "bootstrap-vue";
import Loading from "@/components/shared/loading/loading.vue";
import VendorRequestModal from "@/components/vendors/VendorRequestModal/index.vue";
import NoDataFound from "@/components/shared/NoDataFound/index.vue";

export default {
  name: "VendorsRequests",
  components: {
    NoDataFound,
    VendorRequestModal,
    BRow,
    BCol,
    BCard,
    BTable,
    BBadge,
    BButton,
    BAvatar,
    BDropdown,
    BFormInput,
    BInputGroup,
    BPagination,
    BDropdownItem,
    Loading,
  },
  data() {
    return {
      cols: [
        {
          key: "#",
          label: "#",
          sortable: false,
        },
        {
          key: "logo",
          label: this.$t("vendors/vendorsList/accepted-vendors/logo"),
          sortable: false,
        },
        {
          key: "store_name",
          label: this.$t("vendors/vendorsList/accepted-vendors/name"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("vendors/vendorsList/vendors-requests/status"),
          sortable: false,
        },
        {
          key: "details",
          label: this.$t("vendors/vendorsList/accepted-vendors/details"),
          sortable: false,
        },
      ],
      rows: null,
      perPage: 10,
      currentPage: 1,
      filter: null,
      filterOn: [],
      requestNumber: 0,
    };
  },
  created() {
    this.getVendorsRequests();
  },
  computed: {
    searchResult() {
      if (this.filter) {
        return this.rows.filter((item) => {
          return item.store_name
            .toLowerCase()
            .includes(this.filter.toLowerCase());
        });
      } else {
        return this.rows;
      }
    },
  },
  methods: {
    getVendorsRequests() {
      this.$http
        .get("admin/vendors?approved=false")
        .then((response) => {
          if (response.status === 200) {
            this.rows = response.data.data;
            this.rows.map((item, index) => {
              item.index = index + 1;
              this.requestNumber += 1;
            });
            this.$emit("requestNumber", this.requestNumber);
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
    filterVendor(vendorId) {
      this.rows = this.rows.filter((vendor) => vendor.id !== vendorId);
    },
    update() {
      this.getVendorsRequests();
    },
  },
};
</script>

<style scoped lang="scss" src="./_index.scss" />
