<template>
  <div class="vendor-request-modal">
    <b-button
      variant="link"
      @click="$bvModal.show(`show-modal-vendor-request-${row.item.slug}`)"
      :ref="`ref-show-modal-vendor-request-${row.item.slug}`"
    >
      {{ $t("vendors/vendorsList/vendors-requests/view") }}
    </b-button>
    <b-modal
      centered
      :id="`show-modal-vendor-request-${row.item.slug}`"
      modal-class="add-modal"
      title-class="add-modal-title"
      header-class="add-modal-header"
      footer-class="add-modal-footer"
      cancel-variant="outline-primary"
      :ok-title="$t('g.accept')"
      :cancel-title="$t('g.reject')"
      @ok="approveVendor"
      hide-footer
      @close="handleReset"
    >
      <div class="vendor-request-modal-content" v-if="!rejecting">
        <b-img
          :src="vendoreHeaderImage"
          :alt="row.item.store_name"
          class="vendor-request-modal-cover"
          fluid-grow
        />
        <b-avatar
          class="vendor-request-modal-avatar"
          size="8rem"
          :alt="row.item.store_name"
          :src="
            $helpers.filterImages('path', row.item.logo, row.item.store_name)
          "
        />
        <b-row class="vendor-request-modal-details">
          <b-col cols="12">
            <b-form-group
              label-cols="12"
              label-for="store_name"
              :label="$t('vendors/vendorsList/vendors-requests/vendor_name')"
            >
              <b-form-input
                id="store_name"
                :value="row.item.store_name"
                readonly
              />
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-for="description"
              :label="$t('vendors/vendorsList/vendors-requests/description')"
            >
              <b-form-textarea
                id="description"
                :value="row.item.about"
                rows="5"
                no-resize
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <ul class="vendor-info">
          <li>
            <span>{{ $t("g.id_number") }}:</span>
            <strong>{{ row.item.id_number }}</strong>
          </li>

          <li>
            <span>{{ $t("g.id_number_image") }}:</span>

            <strong>
              <a
                v-if="id_number_image"
                :href="id_number_image"
                target="_blank"
                >{{ $t("g.view_file") }}</a
              >
              <span v-else>{{ $t("g.id_image_not_found") }}</span>
            </strong>
          </li>
          <li>
            <span>{{ $t("g.commercial_number") }}:</span>
            <strong>{{
              row.item.commercial_number
                ? row.item.commercial_number
                : $t("g.noData")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.commercial_expiry_date") }}:</span>
            <strong>{{
              row.item.id_expiry_date ? row.item.id_expiry_date : $t("g.noData")
            }}</strong>
          </li>
          <li>
            <span>{{ $t("g.commercial_number_image") }}:</span>
            <strong>
              <a
                v-if="commercial_number_image"
                :href="commercial_number_image"
                target="_blank"
                >{{ $t("g.view_file") }}</a
              >
              <span v-else>{{ $t("g.commercial_image_not_found") }}</span>
            </strong>
          </li>
        </ul>
        <div
          class="d-flex justify-content-end"
          v-if="!noActions && globalPermission.vendors.update"
        >
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="rejecting = true"
          >
            {{ $t("g.reject") }}
          </b-button>
          <b-button variant="primary" @click="approveVendor">
            {{ $t("g.accept") }}
          </b-button>
        </div>
      </div>
      <div v-else>
        <validation-observer ref="vReject">
          <b-form>
            <b-form-group :label="$t('g.rejection_reason')">
              <validation-provider
                #default="{ errors }"
                name="Rejection reason"
                rules="required"
              >
                <b-form-textarea
                  rows="5"
                  v-model="message"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('g.rejection_reason')"
                />
                <small class="text-danger">{{
                  errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
                }}</small></validation-provider
              >
            </b-form-group>
            <b-button
              style="float: inline-end"
              variant="danger"
              @click="deleteVendor"
            >
              {{ $t("g.submit") }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BImg,
  BCol,
  BRow,
  BAvatar,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BModal,
  BForm,
} from "bootstrap-vue";

export default {
  name: "VendorRequestModal",
  components: {
    BRow,
    BCol,
    BAvatar,
    BButton,
    BImg,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    noActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rejecting: false,
      message: null,
      id_number_image: null,
      commercial_number_image: null,
      okStatus: false,
      cancelStatus: false,
      vendoreHeaderImage: this.$helpers.isset(this.row.item.header)
        ? this.$helpers.filterImages(
            "path",
            this.row.item.header,
            this.row.item.store_name
          )
        : require("@/assets/images/pages/vendor-cover.jpeg"),
    };
  },
  created() {
    if (this.row) {
      this.id_number_image = this.row.item.id_number_image?.path;
      this.commercial_number_image =
        this.row.item.commercial_number_image?.path;
    }
  },
  methods: {
    approveVendor() {
      let formData = new FormData();
      formData.append("is_approved", 1);

      this.$http
        .post(`admin/vendors/${this.row.item.slug}?_method=PUT`, formData)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$helpers.makeToast(
              "success",
              res.data.message,
              res.data.message
            );
            this.okStatus = true;
            this.$emit("vendorApproved", this.row.item.id);
            this.$store.dispatch("vendor/setIsApproved", 1);
            this.$bvModal.hide(
              `show-modal-vendor-request-${this.row.item.slug}`
            );
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    handleReset() {
      this.rejecting = false;
    },
    deleteVendor() {
      this.$refs.vReject.validate().then((success) => {
        if (success) {
          let rowData = {
            message: this.message,
          };
          this.$http
            .delete(`admin/vendors/${this.row.item.slug}`, {
              data: rowData,
              headers: {
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                this.$emit("update-table", true);
                this.cancelStatus = true;
                this.rejecting = false;
              }
            })
            .catch((err) => {
              this.$helpers.handleError(err);
            });
        } else {
          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" src="./_index.scss" />
