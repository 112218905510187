<template>
  <b-modal
    centered
    cancel-variant="outline-primary"
    :ok-title-html="$t('g.save_changes')"
    :cancel-title="$t('cancel-button')"
    @ok="editVendor"
    @hidden="resetData"
    id="edit-modal-vendor"
    :title="$t('g.edit_vendor')"
  >
    <validation-observer ref="editVendor">
      <div class="vendor-request-modal-content">
        <div
          class="vendor-request-modal-cover position-relative"
          :style="{ backgroundImage: 'url(' + getPathImage(header_path) + ')' }"
        >
          <label
            for="header_image"
            class="position-absolute p-0 cursor-pointer"
          >
            <img :src="cameraIcon" alt="camera icon" />
          </label>
          <b-form-file
            id="header_image"
            v-model="header_image"
            accept="image/*"
            class="d-none"
          />
        </div>
        <b-avatar
          class="vendor-request-modal-avatar"
          size="8rem"
          :alt="store_name"
          :src="localLogoPath || logo_path || logo_image"
          badge
          badge-variant="light"
        >
          <template #badge>
            <label for="logo" class="cursor-pointer">
              <FeatherIcon icon="CameraIcon" class="text-primary" size="17" />
            </label>
            <b-form-file
              id="logo"
              v-model="logo"
              accept="image/*"
              class="d-none"
              @change="handelLogoChange"
            />
          </template>
        </b-avatar>

        <b-form-group
          label-cols="12"
          label-for="store_name"
          :label="$t('vendors/vendorsList/vendors-requests/vendor_name')"
        >
          <validation-provider
            #default="{ errors }"
            name="store owner"
            rules="required"
          >
            <b-form-input id="store_name" v-model="store_name" />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-for="about"
          :label="$t('vendors/vendorsList/vendors-requests/description')"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
            name="about"
          >
            <b-form-textarea id="about" v-model="about" rows="5" no-resize />
            <small class="text-danger">{{
              errors[0] ? $t(`g.alert.${errors[0]}`) : errors[0]
            }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-for="priority"
          :label="$t('vendors/vendorsList/vendors-requests/priority')"
        >
          <b-form-select
            id="priority"
            v-model="priority"
            :options="priority_options"
          />
        </b-form-group>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormSelect,
  BAvatar,
  BFormInput,
  BCol,
  BFormGroup,
  BFormTextarea,
  BImg,
  BRow,
  BFormFile,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BModal,
    BFormSelect,
    BAvatar,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BRow,
    BCol,
    BFormGroup,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  props: [
    "page_data",
    "header_path",
    "logo_path",
    "about",
    "store_name",
    "priority",
  ],
  data() {
    return {
      logo: null,
      localLogoPath: null,
      header_image: null,
      logo_image: require("@/assets/images/pages/userAvatar.jpg"),
      cameraIcon: require("@/assets/images/icons/camera.svg"),
      HeaderImageDefault: require("@/assets/images/pages/vendor-cover.jpeg"),
      priority_options: [
        {
          text: this.$t("vendors/please_select_the_priority"),
          value: null,
          disabled: true,
        },
        {
          text: this.$t("vendors/vendorsList/accepted-vendors/very_high"),
          value: 5,
        },
        {
          text: this.$t("vendors/vendorsList/accepted-vendors/high"),
          value: 4,
        },
        {
          text: this.$t("vendors/vendorsList/accepted-vendors/medium"),
          value: 3,
        },
        {
          text: this.$t("vendors/vendorsList/accepted-vendors/low"),
          value: 2,
        },
        {
          text: this.$t("vendors/vendorsList/accepted-vendors/very_low"),
          value: 1,
        },
      ],
    };
  },
  methods: {
    async editVendor() {
      const validate = await this.$refs.editVendor.validate();
      if (validate) {
        try {
          const formData = new FormData();

          if (this.$helpers.isset(this.store_name)) {
            formData.append("store_name", this.store_name);
          }
          if (this.$helpers.isset(this.about)) {
            formData.append("about", this.about);
          }
          if (this.$helpers.isset(this.priority)) {
            formData.append("priority", parseInt(this.priority));
          }
          if (this.$helpers.isset(this.logo)) {
            formData.append("logo", this.logo);
          }
          if (this.$helpers.isset(this.header_image)) {
            formData.append("header", this.header_image);
          }

          const response = await this.$http.post(
            `/admin/vendors/${this.page_data.slug}?_method=PUT`,
            formData
          );
          if (response.status === 200) {
            this.$emit("vendorUpdated");
            this.$helpers.makeToast(
              "success",
              response.data.message,
              response.data.message
            );
          }
        } catch (err) {
          this.$helpers.handleError(err);
        } finally {
          this.resetData();
        }
      } else {
        this.$bvModal.show("edit-modal-vendor");
      }
    },
    resetData() {
      this.$emit("resetPopup", true);
    },
    getPathImage(path) {
      return path ? path : this.HeaderImageDefault;
    },
    handelLogoChange(event) {
      const file = event.target.files[0];
      this.localLogoPath = URL.createObjectURL(file);
    },
  },
};
</script>

<style lang="scss" src="./_EditVendorModal.scss"></style>
